import PlanningBody from './components/planningBody';
import PlanningHeader from './components/planningHeader';
import React, { useState, useContext, useEffect } from 'react';
import LeaveResume from '@helpers/LeaveResume';
import useStatus from '@hooks/useStatus';
import FilterData from '@src/helpers/FilterData';
import PlanningContext from '@context/PlanningContext';
import PlanningConfig from '@helpers/FiltersConfigs/PlanningConfig';

const PlanningComponent = ({ selectedOptions, setSelectedOptions }) => {
  const {
    isFilterModalOpen,
    setIsFilterModalOpen,
    setSelectedModels,
    setFilters,
    isTagsDisplay,
    setUsers,
    setPage,
  } = useContext(PlanningContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState();

  //Filter modal
  const getStatusByTag = useStatus();

  useEffect(() => {
    setSelectedOptions([]);
  }, [isTagsDisplay]);

  return (
    <div className='min-w-[80%] bg-white'>
      <PlanningHeader setSelectedOptions={setSelectedOptions} />
      <div className='min-w-[80% overflow-x-scroll]'>
        <PlanningBody
          setSelectedLeave={setSelectedLeave}
          setIsModalOpen={setIsModalOpen}
        />
      </div>
      {isModalOpen && (
        <LeaveResume
          currentLeave={selectedLeave}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          getStatusByTag={getStatusByTag}
        />
      )}
      <FilterData
        manager={() => {
          return PlanningConfig(
            setFilters,
            setUsers,
            isTagsDisplay,
            setIsFilterModalOpen,
            selectedOptions,
            setSelectedOptions,
            setSelectedModels,
            setPage
          );
        }}
        isOpen={isFilterModalOpen}
        setIsOpen={setIsFilterModalOpen}
      />
    </div>
  );
};

export default PlanningComponent;
