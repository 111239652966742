import React, { useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import PlanningContext from '@context/PlanningContext';

const PlanningHeaderNavBar = () => {
  const { t } = useTranslation();
  const { period, month, year, changeWeek, changeMonth, daysOfPeriod } =
    useContext(PlanningContext);

  const changePeriodNumber = (value) => {
    if (period === 'week') {
      changeWeek(value);
    } else {
      changeMonth(value);
    }
  };

  return (
    daysOfPeriod.length > 0 && (
      <div className='flex justify-between h-10 px-4 pt-1 sticky top-3'>
        <div
          className='flex cursor-pointer'
          onClick={() => {
            changePeriodNumber(-1);
          }}
        >
          <FontAwesomeIcon
            height={10}
            icon={faAngleLeft}
            className='text-red-600'
          />
          <p className='text-lightgrey-500 ml-2' style={{ fontSize: 10 }}>
            {period === 'week' ? t('previousWeek') : t('previousMonth')}
          </p>
        </div>
        {period == 'twomonths' && (
          <>
            <p className='text-red-700 font-bold text-xs capitalize'>
              {dayjs()
                .set('year', year)
                .set('month', month)
                .format('MMMM YYYY')}
            </p>
            <p className='text-red-700 font-bold text-xs capitalize'>
              {dayjs()
                .set('year', year)
                .set('month', month)
                .add(1, 'month')
                .format('MMMM YYYY')}
            </p>
          </>
        )}
        {period == 'month' && (
          <>
            <p className='text-red-700 font-bold text-xs capitalize'>
              {dayjs()
                .set('year', year)
                .set('month', month)
                .format('MMMM YYYY')}
            </p>
          </>
        )}
        {period == 'week' && (
          <div className='flex'>
            <p className='text-red-700 font-bold text-xs capitalize'>{`${t(
              'from'
            )} ${daysOfPeriod[0].get('date')} ${t('to')} ${daysOfPeriod[
              daysOfPeriod.length - 1
            ].format('D MMMM YYYY')}`}</p>
            <p className='text-lightgrey-600 text-xs ml-1'>{`(${t(
              'week'
            ).toLowerCase()} ${daysOfPeriod[
              daysOfPeriod.length - 1
            ].week()})`}</p>
          </div>
        )}
        <div
          className='flex cursor-pointer'
          onClick={() => {
            changePeriodNumber(1);
          }}
        >
          <p className='text-lightgrey-500 mr-2' style={{ fontSize: 10 }}>
            {period === 'week' ? t('nextWeek') : t('nextMonth')}
          </p>
          <FontAwesomeIcon
            height={10}
            width={10}
            icon={faAngleRight}
            className='text-red-600'
          />
        </div>
      </div>
    )
  );
};

export default PlanningHeaderNavBar;
