import React, { useContext } from 'react';
import GroupCollapse from './groupCollapse';
import PlanningContext from '@context/PlanningContext';

const CollapseBody = ({ leaveTypes, setWidth, isExpanded }) => {
  const { usersGroupedBySite, selectedModels } = useContext(PlanningContext);

  return (
    <table className='bg-white w-full'>
      <tbody>
        <tr>
          <th>
            {usersGroupedBySite?.map((groupedUsers) => {
              //Display only tags selected in filter
              //because an user can have multiple tags
              if (
                selectedModels.length === 0 ||
                selectedModels.some(
                  (modelId) => groupedUsers.name.split('_')[2] === modelId
                )
              )
                return (
                  <GroupCollapse
                    key={groupedUsers?.name}
                    groupedUsers={groupedUsers}
                    setWidth={setWidth}
                    isExpanded={isExpanded}
                    leaveTypes={leaveTypes}
                  />
                );
            })}
          </th>
        </tr>
      </tbody>
    </table>
  );
};

export default CollapseBody;
