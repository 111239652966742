import React, { useContext } from 'react';
import PeriodToggleGroup from '../pageHeader/components/periodToggleGroup';
import LeaveTypeLegend from './components/leave_type_legend';
import StatusLegend from './components/status_legend';
import SchoolZoneLegend from './components/school_zone_legend';
import PlanningContext from '@context/PlanningContext';

import { CheckboxCustom } from '@components/UI/Inputs';

import { FilterButton } from '@components/UI/Buttons';

import { ButtonSecondAction } from '@components/UI/Buttons';

const pageHeader = ({
  leaveTypes,
  setSelectedOptions,
  filters,
  setFilters,
}) => {
  const {
    t,
    setIsFilterModalOpen,
    reversePrimary,
    setReversePrimary,
    setUsers,
  } = useContext(PlanningContext);

  const onChangePrimary = (e) => {
    const { checked } = e.target;
    setReversePrimary(checked);
    localStorage.setItem('reverse_primary', checked);
  };

  return (
    <div className='flex justify-between bg-white mb-6 px-2 h-24'>
      <div className='flex flex-col ll:flex-row flex-wrap px-2 w-1/2 gap-x-2'>
        <LeaveTypeLegend leaveTypes={leaveTypes} />
        <StatusLegend />
      </div>

      <div className='flex flex-col justify-between items-center pb-2'>
        <PeriodToggleGroup />
        <CheckboxCustom
          label={t('displayTags')}
          id='reversePrimary'
          labelClass='text-xs text-lightgrey-400 '
          onChange={onChangePrimary}
          checked={Boolean(reversePrimary)}
          tooltip
          tooltipTitle={t('switchPrimarySecondary')}
        />
      </div>
      <div className=' flex flex-col justify-between pb-2'>
        <div className='flex items-center gap-x-2 self-end'>
          <ButtonSecondAction
            onClick={() => setIsFilterModalOpen(true)}
            type='button'
          >
            {t('filters')}
          </ButtonSecondAction>
          <FilterButton
            selectedOptions={filters}
            onClick={() => {
              setUsers([]);
              setSelectedOptions({});
              setFilters([]);
            }}
          />
        </div>
        <SchoolZoneLegend />
      </div>
    </div>
  );
};

export default pageHeader;
