import React, { useState } from 'react';
import CollapseBody from '@components/Planning/components/collapseLeftBar/components/collapseBody';
import CollapseHeader from '@components/Planning/components/collapseLeftBar/components/collapseHeader';
import { classNames } from '@utility/Utils';

const CollapseLeftBar = ({ leaveTypes, isAdmin }) => {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <section className='sticky left-0 z-10 bg-white w-56'>
      <div className={classNames(isExpanded ? 'w-56' : 'w-0')}></div>
      <div
        className={classNames(
          isExpanded ? 'absolute' : 'w-full',
          ' bg-white shadow-planning h-full '
        )}
      >
        <CollapseHeader
          leaveTypes={leaveTypes}
          isExpanded={isExpanded}
          setExpanded={setExpanded}
          isAdmin={isAdmin}
        />
        <CollapseBody leaveTypes={leaveTypes} isExpanded={isExpanded} />
      </div>
    </section>
  );
};

export default CollapseLeftBar;
