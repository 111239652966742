import React from 'react';
import { useTranslation } from 'react-i18next';

const status = [
  {
    id: 1,
    tag: 'VALIDATED',
    name: 'Validé',
    color: '#9DD400',
  },
  {
    id: 2,
    tag: 'SUBMITTED',
    name: 'Soumis à validation',
    color: '#FFBE0B',
  },
  {
    id: 3,
    tag: 'TRANSMITTED',
    name: 'Transmis en paie',
    color: '#5FAEDB',
  },
];

const StatusLegend = () => {
  const { t } = useTranslation();

  return (
    <div className='w-1/4'>
      <h2 className='text-red-600 font-semibold text-base mb-2'>
        {t('status')}
      </h2>
      <div className='flex flex-col'>
        {status.map((status, index) => {
          return (
            <div key={index}>
              <StatusLegendItem label={status.name} color={status.color} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const StatusLegendItem = ({ label, color }) => {
  return (
    <div className='flex items-center gap-x-2 bg-primary h-6'>
      <div
        className='w-[12px] h-[12px] rounded-xl'
        style={{ background: color }}
      ></div>
      <p style={{ fontSize: 10 }} className='text-lightgrey-400 truncate'>
        {label ?? ''}
      </p>
    </div>
  );
};

export default StatusLegend;
