import React, { useContext, useRef, useMemo, useCallback } from 'react';
import { Trash } from 'lucide-react';
import { capitalizeFirstLetter, classNames } from '@utility/Utils';
import PlanningContext from '@context/PlanningContext';
import UserContext from '../../../../../context/UserContext';

export default function GroupCollapse({ groupedUsers, isExpanded, leaveTypes }) {
  const scrollRef = useRef();

  const groupSplitedName = groupedUsers.name.split('_');
  const { hoverUser, setHoverUser, detachUser } = useContext(PlanningContext);
  const { actionsDirector, isDirector } = useContext(UserContext);

  const detachUserFn = (user) => {
    actionsDirector([user], 'detach');
    detachUser([user]);
  };

  const handleClick = useCallback((user) => {
    setHoverUser(user);
  }, [setHoverUser]);

  return (
    <div className='w-full' ref={scrollRef}>
      <div className='w-full px-2'>
        <p className='text-sm bg-lightgrey-50 flex items-center rounded-lg h-8 font-montserrat font-bold'>
          <span className='overflow-hidden text-ellipsis pl-2 text-center w-full'>
            {groupSplitedName[1]}
          </span>
        </p>
      </div>
      <table
        className='w-full'
        style={{
          tableLayout: 'fixed',
          emptyCells: 'show',
          borderCollapse: 'collapse',
        }}
      >
        <tbody>
          {groupedUsers.users.map((user, index) => {
            const isLastIndex = index === groupedUsers.users.length - 1;
            return (
              <tr
                key={user.id}
                className={classNames(
                  'w-full pr-2 pl-3 transition-colors cursor-pointer hover:bg-[rgb(88,17,26,0.15)]'
                )}
                style={{ height: '30px', backgroundColor: hoverUser?.id === user.id ? 'rgb(88 17 26 / 15%)' : '', }}
                onClick={() => handleClick(user)}
              >
                <td
                  className='gap-x-2 pl-3 align-middle'
                  style={{
                    borderBottom: isLastIndex
                      ? '1px solid transparent'
                      : '1px solid #D3D3D3',
                    borderTop: '1px solid white',
                    boxSizing: 'border-box',
                  }}
                >
                  <div className='flex gap-x-2'>
                    <p className='text-lightgrey-400 text-base flex gap-x-1 w-[180px]'>
                      <span className='uppercase truncate'>
                        {user?.lastname}
                      </span>
                      <span className='truncate'>
                        {capitalizeFirstLetter(user?.firstname)}
                      </span>
                      {user.directors_exists && isDirector && (
                        <button
                          onClick={() => detachUserFn(user)}
                          className='my-auto'
                        >
                          <Trash className='text-center text-xs cursor-pointer text-gray-800 font-bold h-3 w-3' />
                        </button>
                      )}
                    </p>
                    {isExpanded &&
                      leaveTypes.map((leaveType, index) => {
                        const leaveCount = user?.user_leave_counts.find(
                          (lc) =>
                            lc.leave_type_id === leaveType.id &&
                            ((lc.is_last_year && leaveType.name.includes('-1')) ||
                              (!lc.is_last_year && !leaveType.name.includes('-1')))
                        );
                        return (
                          <p
                            key={index}
                            className={classNames(
                              leaveCount?.balance < 0
                                ? 'text-primary1'
                                : 'text-lightgrey-400',
                              'text-xs flex items-center justify-center pr-6 pl-6 w-20'
                            )}
                          >
                            {leaveCount?.balance ?? '-'}
                          </p>
                        );
                      })}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}