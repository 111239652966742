import React, { useContext } from 'react';
import PlanningContext from '@context/PlanningContext';

const SchoolZoneLegend = () => {
  const { holidayZones } = useContext(PlanningContext);

  return (
    <div className='flex justify-end'>
      {holidayZones.map((zone, index) => {
        return (
          <div key={index}>
            <SchoolZoneItem zone={zone} />
          </div>
        );
      })}
    </div>
  );
};

const SchoolZoneItem = ({ zone }) => {
  const { t } = useContext(PlanningContext);
  return (
    <div className='flex items-center mr-2 ml-2'>
      <span
        className='w-4 h-1 rounded-md mr-2'
        style={{ background: zone.color }}
      ></span>
      <p style={{ fontSize: '9px', color: zone.color, fontWeight: 400 }}>{`${t(
        'zone'
      ).toUpperCase()} ${zone.label.toUpperCase()}`}</p>
    </div>
  );
};

export default SchoolZoneLegend;
