import React, { useContext } from 'react';
import Popover from '@mui/material/Popover';
import PlanningContext from '@context/PlanningContext';
import { formatArrayWithZeroLast } from '@utility/Utils';

const LeaveTypeLegend = ({ leaveTypes }) => {
  const { t } = useContext(PlanningContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const sortedLeaveTypes = formatArrayWithZeroLast(leaveTypes);
  const slicedLeaveTypes = sortedLeaveTypes.slice(0, 6);
  return (
    <div className='w-1/2'>
      <h2 className='text-red-600 font-semibold text-base mb-2'>
        {t('leaveTypes')}
      </h2>
      <div className='flex items-center'>
        <div className='grid grid-cols-2 gap-x-4'>
          {leaveTypes.length > 0 &&
            slicedLeaveTypes.map((type, index) => (
              <div key={index}>
                <LeaveTypeLegendItem label={type.name} color={type.color} />
              </div>
            ))}
        </div>
        {leaveTypes.length > 6 && (
          <span
            style={{ background: '#191919', fontSize: 11 }}
            className='text-white px-2 py-1 rounded-md cursor-pointer h-8 mr-2'
            onClick={handleClick}
          >
            {`+${leaveTypes.length - 4}`}
          </span>
        )}
      </div>

      <MorePopup
        id={id}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        t={t}
        leaveTypes={leaveTypes}
      />
    </div>
  );
};

const MorePopup = ({ id, open, anchorEl, handleClose, t, leaveTypes }) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className='p-5'>
        <p className='text-red-600 font-semibold text-base mb-2'>
          {t('leaveType')}
        </p>
        {leaveTypes.map((type, index) => {
          return (
            <div className='flex pt-3' key={index}>
              <div
                className='w-6 h-2 rounded-xl mr-2'
                style={{ background: type.color }}
              ></div>
              <p
                style={{ fontSize: 10 }}
                className='ml-2 mr-2 text-lightgrey-400'
              >
                {type.name ?? ''}
              </p>
            </div>
          );
        })}
      </div>
    </Popover>
  );
};

const LeaveTypeLegendItem = ({ label, color, key }) => {
  return (
    label != undefined && (
      <div className='flex items-center gap-x-2 '>
        <div
          className='min-w-[16px] w-4 h-2 rounded-xl '
          style={{ background: color }}
        ></div>
        <span style={{ fontSize: 10 }} className='text-lightgrey-400 truncate'>
          {label}
        </span>
      </div>
    )
  );
};

export default LeaveTypeLegend;
