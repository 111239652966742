import React from 'react';
import PlanningDaysHeader from './planningDaysHeader';
import PlanningHeaderNavBar from './planningHeaderNavBar';
import PlanningHolidayHeader from './planningHolidayHeader';
import PlanningPublicHolidayHeader from './planningPublicHolidayHeader';

const PlanningHeader = () => {
  return (
    <div
      style={{ height: '130px', zIndex: 9 }}
      className='bg-white sticky top-[65px]'
    >
      <PlanningHeaderNavBar />
      <table className='w-full' style={{ tableLayout: 'fixed' }}>
        <PlanningDaysHeader />
      </table>
      <table className='w-full' style={{ tableLayout: 'fixed' }}>
        <PlanningHolidayHeader />
      </table>
      <table className='w-full' style={{ tableLayout: 'fixed' }}>
        <PlanningPublicHolidayHeader />
      </table>
    </div>
  );
};

export default PlanningHeader;
