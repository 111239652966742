import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import {
  DefaultInput,
  SelectCustom,
  DateTimePickerCustom,
  AsyncPaginateCustom,
  SwitchCustom,
  CheckboxCustom,
} from '@components/UI/Inputs';

import SlideOver from '@components/UI/SlideOver';
import {
  ButtonFirstAction,
  ButtonSecondAction,
  ButtonThirdAction,
} from '@src/components/UI/Buttons';

function FilterData({ isOpen, setIsOpen, manager }) {
  const { t } = useTranslation();

  const config = manager();

  const renderField = (field, index) => {
    switch (field.type) {
      case 'select':
        return (
          <div key={index}>
            <SelectCustom
              name={field.name}
              label={field.label}
              placeholder={field.placeholder}
              customClass={field.customClass}
              type={field.type}
              isMulti={field.isMulti}
              inputClass={field.inputClass}
              containerClass={field.containerClass}
              options={field.options}
              onChange={field.onChange}
              value={field.value}
              {...field.props}
            />
          </div>
        );
      case 'date':
        return (
          <div key={index}>
            <DateTimePickerCustom
              name={field.name}
              label={field.label}
              customClass={field.customClass}
              inputClass={field.inputClass}
              containerClass={field.containerClass}
              onChange={field.onChange}
              value={field.value}
              {...field.props}
            />
          </div>
        );
      case 'asyncPaginate':
        return (
          <div key={index}>
            <AsyncPaginateCustom
              name={field.name}
              label={field.label}
              customClass={field.customClass}
              placeholder={field.placeholder}
              type={field.type}
              inputClass={field.inputClass}
              containerClass={field.containerClass}
              loadOptions={field.loadOptions}
              onChange={field.onChange}
              additional={field.additional}
              value={field.value}
              {...field.props}
            />
          </div>
        );
      case 'switch':
        return (
          <div key={index}>
            <SwitchCustom
              name={field.name}
              label={field.label}
              customClass={field.customClass}
              inputClass={field.inputClass}
              containerClass={field.containerClass}
              onChange={field.onChange}
              value={field.value}
              {...field.props}
            />
          </div>
        );
      case 'checkbox':
        return (
          <div key={index}>
            <CheckboxCustom
              name={field.name}
              label={field.label}
              customClass={field.customClass}
              inputClass={field.inputClass}
              containerClass={field.containerClass}
              onChange={field.onChange}
              checked={field.checked}
              {...field.props}
            />
          </div>
        );
      default:
        return (
          <div key={index}>
            <DefaultInput
              type={field.type}
              onChange={field.onChange}
              placeholder={field.placeholder}
              value={field.value}
              name={field.name}
              label={field.label}
              customClass={field.customClass}
              inputClass={field.inputClass}
              containerClass={field.containerClass}
            />
          </div>
        );
    }
  };

  return (
    <SlideOver
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={t('filters')}
      onClose={() => {
        config.handleClose();
      }}
    >
      <div className='flex flex-col gap-y-6'>
        {config.fields.map((field, index) => {
          return renderField(field, index);
        })}
      </div>
      <div className='my-4 mt-12 flex justify-center items-center'>
        <ButtonThirdAction
          onClick={() => {
            config.handleReset();
          }}
        >
          {t('reset')}
        </ButtonThirdAction>
      </div>
      <div className='flex gap-x-3 justify-center mt-4'>
        <ButtonFirstAction
          onClick={() => {
            config.handleClose();
          }}
          type='button'
        >
          {t('cancel')}
        </ButtonFirstAction>
        <ButtonSecondAction
          onClick={(e) => {
            config.handleSubmit(e);
          }}
          type='submit'
        >
          {t('save')}
        </ButtonSecondAction>
      </div>
    </SlideOver>
  );
}

FilterData.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  manager: PropTypes.func.isRequired,
};

export default FilterData;
