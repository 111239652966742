import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import PlanningContext from '@context/PlanningContext';
import congesService from '@data/congesService';

import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const PlanningHolidayHeader = () => {
  const { daysOfPeriod, holidayZones, year } = useContext(PlanningContext);
  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    fetchHolidays();
  }, [year]);

  const fetchHolidays = async () => {
    try {
      const res = await congesService.get(
        `/v1/leaves/holidays?startDate=${dayjs()
          .startOf('year')
          .year(year)
          .format('YYYY-MM-DD')}&endDate=${dayjs()
          .endOf('year')
          .year(year)
          .format('YYYY-MM-DD')}`
      );
      setHolidays(res.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getHolidayAtThisDate = (date, zone) => {
    let holiday = null;
    if (holidays.length !== 0) {
      const holidaysInZone = holidays.filter((h) =>
        h.zones.toLowerCase().includes(zone)
      );
      if (holidaysInZone.length > 0) {
        //Check if there is holiday at this date
        for (let i = 0; i < holidaysInZone.length; i++) {
          const hasHoliday = date?.isBetween(
            dayjs(holidaysInZone[i]?.start_date),
            dayjs(holidaysInZone[i]?.end_date),
            'day',
            '[)'
          );
          if (hasHoliday) {
            holiday = holidays[i];
          }
        }
      }
    }
    return holiday;
  };

  return (
    <tbody>
      {holidayZones.map((zone, index) => {
        return (
          <tr key={index}>
            {daysOfPeriod.length > 0 &&
              daysOfPeriod.map((date, index) => {
                const holiday = getHolidayAtThisDate(date, zone.label);
                return (
                  <td key={index} className='pt-1 bg-white'>
                    <div
                      style={{
                        background: holiday != null ? zone.color : 'white',
                        height: 2,
                      }}
                    ></div>
                  </td>
                );
              })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default PlanningHolidayHeader;
