import PlanningContext from '@context/PlanningContext';
import congesService from '@data/congesService';
import { Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import React, { useContext, useEffect } from 'react';

dayjs.extend(isBetween);

const WhitDaysTooltip = ({ publicHoliday }) => {
  return (
    <span className='text-xs font-normal'>{publicHoliday?.localName}</span>
  );
};

const PlanningPublicHolidayHeader = () => {
  const { daysOfPeriod, year, publicHolidays, setPublicHolidays } =
    useContext(PlanningContext);

  useEffect(() => {
    fetchHolidays();
  }, [year]);

  const fetchHolidays = async () => {
    try {
      const res = await congesService.get(
        `/v1/leaves/public-holidays?startDate=${dayjs()
          .startOf('year')
          .year(year)
          .format('YYYY-MM-DD')}&endDate=${dayjs()
          .endOf('year')
          .year(year + 1)
          .format('YYYY-MM-DD')}`
      );
      setPublicHolidays(res.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getHolidayAtThisDate = (date) => {
    let holiday = null;
    if (publicHolidays.length !== 0) {
      //Check if there is holiday at this date
      for (const element of publicHolidays) {
        const hasHoliday =
          dayjs(element.date).format('DD/MM/YYYY') ===
          dayjs(date).format('DD/MM/YYYY');
        if (hasHoliday) {
          holiday = element;
        }
      }
    }
    return holiday;
  };

  return (
    <tbody>
      <tr>
        {daysOfPeriod.map((date) => {
          const publicHoliday = getHolidayAtThisDate(date);
          return (
            <td
              key={`publicHoliday_${date}`}
              className='bg-transparent rounded-full'
            >
              <div
                style={{
                  background: publicHoliday != null ? 'black' : 'transparent',
                }}
                className='rounded-full w-[18px] h-[18px] p-1 ml-auto mr-auto flex items-center justify-center'
              >
                {publicHoliday !== null && (
                  <Tooltip
                    title={<WhitDaysTooltip publicHoliday={publicHoliday} />}
                    className='z-50 cursor-pointer bg-dark'
                    arrow
                  >
                    <p className='text-center text-white text-[9px]'>
                      {publicHoliday != null ? 'F' : ''}
                    </p>
                  </Tooltip>
                )}
              </div>
            </td>
          );
        })}
      </tr>
    </tbody>
  );
};

export default PlanningPublicHolidayHeader;
