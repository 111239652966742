import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { CheckboxCustom } from '@components/UI/Inputs';
import PlanningContext from '@context/PlanningContext';
import UserContext from '@context/UserContext';
import AddCollaborator from '../../pageHeader/components/AddCollaborator';
import { ChevronLeft } from 'lucide-react';
const CollapseHeader = ({ leaveTypes, isExpanded, setExpanded }) => {
  const { t } = useTranslation();
  const {
    setIsTagsDisplay,
    setFilters,
    setPage,
    setSelectedModels,
    setUsers,
    isTagsDisplay,
  } = useContext(PlanningContext);

  const { currentUser, isDirector } = useContext(UserContext);

  const isUserAdmin = currentUser?.profile?.label === 'ADMINISTRATEUR';

  return (
    <div
      style={{
        height: '130px',
      }}
      className='pt-2 shadow-planning flex flex-col gap-2 bg-white p-2 sticky top-[65px] '
    >
      <ChevronLeft
        size={40}
        color='white'
        className={`${
          isExpanded ? '' : 'rotate-180'
        } text-center text-xs cursor-pointer h-5 w-5 rounded-sm bg-primary1`}
        onClick={() => {
          setExpanded((v) => !v);
        }}
      />
      {!isUserAdmin && (
        <div>
          <CheckboxCustom
            label={`${t('displayBy')} ${t('teams')}`}
            labelClass={'text-xs uppercase'}
            checked={isTagsDisplay}
            onChange={() => {
              setFilters([]);
              setUsers([]);
              setPage(1);
              setIsTagsDisplay((prev) => !prev);
              setSelectedModels([]);
            }}
          />
        </div>
      )}
      {!isTagsDisplay && isDirector && <AddCollaborator />}

      <table aria-hidden='true' className='w-full text-left overflow-hidden'>
        <tbody className=''>
          <tr className='w-full  flex items-center' style={{ height: '29px' }}>
            <td
              className='flex items-center gap-x-2'
              style={{
                verticalAlign: 'middle',
              }}
            >
              <p className='w-48 text-gray-400 text-sm align-center'>
                {t('name')}
              </p>
              {isExpanded &&
                leaveTypes?.length > 0 &&
                leaveTypes.map((leaveType, index) => {
                  return (
                    <Tooltip
                      title={leaveType.name}
                      arrow
                      placement='top'
                      key={index}
                    >
                      <p className='text-xs text-gray-400 truncate w-20 text-center'>
                        {leaveType.name}
                      </p>
                    </Tooltip>
                  );
                })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CollapseHeader;
