import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PlanningContext from '@context/PlanningContext';

const PeriodToggleGroup = () => {
  const { t } = useTranslation();
  const { period, setPeriod } = useContext(PlanningContext);

  useEffect(() => {
    if (localStorage.getItem('planning_period')) {
      setPeriod(localStorage.getItem('planning_period'));
    }
  }, []);

  return (
    <div className='flex bg-lightgrey-50 rounded-3xl'>
      <XToggleButton
        value='twomonths'
        period={period}
        setPeriod={setPeriod}
        t={t}
      />
      <XToggleButton
        value='month'
        period={period}
        setPeriod={setPeriod}
        t={t}
      />
      <XToggleButton value='week' period={period} setPeriod={setPeriod} t={t} />
    </div>
  );
};

const XToggleButton = ({ value, period, setPeriod, t }) => {
  const handleChangePeriod = (e) => {
    setPeriod(value);
    localStorage.setItem('planning_period', value);
  };
  return (
    <div
      onClick={handleChangePeriod}
      style={{
        background: period === value ? '#E10600' : '',
        fontSize: 10,
      }}
      className='border-0 rounded-3xl bg-lightgrey-50 px-2 py-3 cursor-pointer w-20 text-center transition-all duration-100 ease-in-out'
    >
      <p
        className='text-gray-400 font-normal'
        style={{
          color: period === value ? 'white' : '',
        }}
      >
        {t(value)}
      </p>
    </div>
  );
};

export default PeriodToggleGroup;
