import React, { useContext, useState } from 'react';
import ModalCustom from '@components/UI/ModalCustom';
import { AsyncPaginateCustom } from '@components/UI/Inputs';
import congesService from '@data/congesService';
import PlanningContext from '@context/PlanningContext';
import { getFullname } from '../../../../../utility/Utils';
import UserContext from '../../../../../context/UserContext';
import { Plus } from 'lucide-react';
const AddCollaborator = () => {
  const { actionsDirector, isDirector } = useContext(UserContext);
  const { addUser } = useContext(PlanningContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);

  const closeModal = () => {
    setSelected([]);
    setPage(1);
    setIsModalOpen(false);
  };

  const AddUsers = () => {
    actionsDirector(selected, 'attach');
    addUser(selected);
    closeModal();
  };

  const loadOptions = async (inputValue) => {
    const response = await congesService
      .post(
        `/v1/users/search?include=user_leave_counts,site,leaves,leaves.status,user_leave_counts.leave_type&searchDirectors&page=${page}`,
        {
          sort: [{ field: 'lastname', direction: 'asc' }],
          search: {
            field: 'name',
            operator: 'like',
            value: '%' + inputValue + '%',
          },
        }
      )
      .catch(() => {
        return { options: [], hasMore: false };
      });

    if (!response) return await response;
    const { current_page, last_page } = await response.data.meta;
    const data = await response.data.data;

    setPage(current_page < last_page ? current_page + 1 : 1);
    const hasMore = current_page !== last_page;
    const options = data?.map((res) => {
      return {
        ...res,
        name: getFullname(res),
      };
    });

    return {
      options,
      hasMore,
    };
  };

  if (!isDirector) return null;
  return (
    <div>
      <button
        onClick={() => setIsModalOpen(true)}
        className='text-xs flex items-center gap-2'
      >
        <Plus
          color='white'
          size={40}
          className='text-center text-xs cursor-pointer h-5 w-5 rounded-sm bg-primary1'
        />
        Ajouter un collaborateur
      </button>
      <ModalCustom
        isOpen={isModalOpen}
        onConfirm={AddUsers}
        onModalClose={closeModal}
        onCancel={closeModal}
        customClass='w-[500px] h-[500px]'
        confirmDisabled={selected.length < 1}
      >
        <AsyncPaginateCustom
          containerClass={'h-full mt-2'}
          name={'new_collaborateur'}
          labelClassnames={'text-center'}
          label={'Ajouter un collaborateur'}
          placeholder={''}
          loadOptions={loadOptions}
          onChange={setSelected}
          isMulti={true}
          value={selected}
          optionLabel={'firstname'}
          optionValue={'id'}
        />
      </ModalCustom>
    </div>
  );
};

export default AddCollaborator;
