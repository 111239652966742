import React, { useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import PlanningContext from '@context/PlanningContext';

const PlanningDaysHeader = () => {
  const { daysOfPeriod } = useContext(PlanningContext);
  const currentDay = dayjs();
  return (
    <tbody>
      <tr className='h-2'>
        {daysOfPeriod.map((date, index) => (
          <td
            key={date}
            className='rounded-t-md'
            style={{
              background: currentDay.isSame(date, 'day') ? '#E10600' : 'white',
            }}
          >
            <p
              className='text-center text-xs text-lightgrey-400'
              style={{
                color: currentDay.isSame(date, 'day') ? 'white' : '#8D8D8D',
              }}
            >
              {dayjs(date).get('date')}
            </p>
          </td>
        ))}
      </tr>
      <tr>
        {daysOfPeriod.map((date, index) => {
          let dayColor = 'rounded-b-md text-center text-xs';
          dayColor += ` ${
            date.day() === 1
              ? currentDay.isSame(date, 'day')
                ? 'text-white'
                : 'text-red-600'
              : 'text-lightgrey-200'
          }`;
          dayColor += ` ${
            currentDay.isSame(date, 'day') ? 'bg-red-600' : 'bg-white'
          }`;
          return (
            <td key={date}>
              <p className={dayColor}>{date.format('dddd')[0].toUpperCase()}</p>
            </td>
          );
        })}
      </tr>
    </tbody>
  );
};

export default PlanningDaysHeader;
